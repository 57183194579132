import * as React from 'react';
import { Link, navigate, useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { LanguageContext, LanguageProvider } from '../contexts/Language';

import norwegian from './norwegian.png';
import english from './english.png';

const HomePage = () => {

    const language = React.useContext(LanguageContext);

    if (!!language) {
        navigate(`/${language}/home`);
    }

    const link = (href, text, imgSrc) => (
        <div style={{
            padding: '5px',
            flexGrow: 1,
        }}>
            <Link
                to={href}
                style={{
                    boxShadow: 'none',
                    color: 'inherit',
                }}
            >
                <img
                    style={{
                        verticalAlign: 'middle',
                        padding: '5px',
                    }}
                    src={imgSrc}
                    alt={text}
                />
                <span style={{paddingLeft: 10}}>{ text }</span>
            </Link>
        </div>
    );

    const imageData = useStaticQuery(graphql`
        query {
            file(relativePath: { eq: "baatliv.jpg" }) {
            childImageSharp {
                fluid {
                    base64
                    aspectRatio
                    src
                    srcSet
                    sizes
                }
            }
            }
        }
    `);

    return (
        <div style={{ display: 'flex', justifyContent: 'center', flexGrow: 1, padding: '10px' }}>
            <div style={{ diplay: 'flex', flexDirection: 'column',  minWidth: 200}}>
                <Img fluid={imageData.file.childImageSharp.fluid} alt={'Canoe by a lake'} />
                <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'space-between', flexWrap: 'wrap' }}>
                    {link('/en/home/', 'Continue in English »', english)}
                    {link('/no/home/', 'Fortsett på Norsk »', norwegian)}
                </div>
            </div>
            <hr />
        </div>
    );
}

export default ({location, data}) => {
    return (
        <LanguageProvider location={location}>
            <HomePage />
        </LanguageProvider>
    )
}


